const SECRET_PATH = '/v5637true';
const NO_RECAPTCHA_STRING = 'H0sAu8mhlUjWN71VehUj';

export const routes = {
  MAIN: `/`,
  LOGIN: `/login${SECRET_PATH}`,
  NO_RECAPTCHA_LOGIN: `/login/${NO_RECAPTCHA_STRING}`,
  LOGOUT: `/logout${SECRET_PATH}`,
  REGISTER: `/register${SECRET_PATH}`,
  RESET_PASSWORD: `/reset-password${SECRET_PATH}`,
  NEW_PASSWORD: `/new-password${SECRET_PATH}`,
  VERIFY_EMAIL: `/verify-email${SECRET_PATH}`,
  VERIFY_EMAIL_FOR_PASSWORD: `/verify-reset${SECRET_PATH}`,
  DASHBOARD: `${SECRET_PATH}`,
  SUBSCRIPTIONS: `/subscriptions${SECRET_PATH}`,
  PRICING: `/pricing${SECRET_PATH}`,
  WEBSITES: `/websites${SECRET_PATH}`,
  PLANS: `/plans${SECRET_PATH}`,
  SETUP: `/setup${SECRET_PATH}`,
  NOTIFICATIONS: `/notifications${SECRET_PATH}`,
  INTEGRATIONS: `/integrations${SECRET_PATH}`,
  SUPPORT: `/support${SECRET_PATH}`,
  COUPONS: `/coupons${SECRET_PATH}`,
  CLIENTS: `/clients${SECRET_PATH}`,
  FEEDBACK: `/feedback${SECRET_PATH}`,
  LOGS: `/logs${SECRET_PATH}`,
  ACCOUNT_TEAM: `/account/team${SECRET_PATH}`,
  ACCOUNT_PROFILE: `/account${SECRET_PATH}`,
  ACCOUNT_PAYMENTS: `/account/payments${SECRET_PATH}`,
  ACCOUNTS: `/accounts${SECRET_PATH}`,
  AUTHENTICATE_LANDING_PAGE_LOGIN: `/auth${SECRET_PATH}`,
  UNSUBSCRIBE_EMAIL: `/unsubscribe-email${SECRET_PATH}`,
  VALIDATE_ACCOUNT: `/validate${SECRET_PATH}`,
  PLATFORM_SETTINGS: `/settings${SECRET_PATH}`,
  OPEN_AI_DOCUMENTS: `/ai-docs${SECRET_PATH}`,
  OPEN_AI_SETTINGS: `/ai-settings${SECRET_PATH}`,
  IP_MANAGEMENT: `/ip_management${SECRET_PATH}`,
  ERROR: '*'
};
