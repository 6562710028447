import { useState, useRef } from 'react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';

export const useAdPlatformIntegrations = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const auth = useAuthUser();
  const { logoutUser } = useLogoutUser();
  const fetchedRef = useRef(false);

  type Partner = 'facebook' | 'google' | 'x' | 'tiktok';

  const getAllStatus = async (userId: string, websiteIds: number[]) => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'status',
      userId,
      websiteIds,
    };
  
    try {
      const response = await fetch(
        'https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      setLoading(false);
      if (result) {
        return result;
      } else {
        setErrorMessage('Failed to fetch platform statuses');
        return null;
      }
    } catch (error) {
      setErrorMessage('Error fetching platform statuses');
      setLoading(false);
      return null;
    }
  };
  
  const disconnectPlatform = async (partner: 'facebook' | 'google' | 'x' | 'tiktok', userId: string, websiteIds: number[]) => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'disconnect',
      partner,
      userId,
      websiteIds
    };
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result.status === 'success') {
        return true;
      } else {
        if (result.reason === 'token_expired') {
          await logoutUser();
        }
        setErrorMessage(result.message || `Failed to disconnect ${partner} platform`);
        return false;
      }
    } catch (error) {
      setErrorMessage(`Error disconnecting ${partner} platform`);
      setLoading(false);
      return false;
    }
  };

  const authorizePlatform = async (partner: Partner, userId: string, websiteIds: number[]): Promise<{ url: string; codeVerifier?: string; state?: string } | null> => {
    setLoading(true);
    setErrorMessage(null);
    const payload = {
      action: 'authorize',
      partner,
      userId,
      websiteIds,
      redirectType:"localm"
    };
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result && typeof result === 'object' && 'url' in result) {
        if (partner === 'x') {
          return {
            url: result.url,
            codeVerifier: result.codeVerifier,
            state: result.state
          };
        }
        return { url: result.url };
      } else {
        setErrorMessage(`Failed to authorize ${partner} platform: Invalid response format`);
        return null;
      }
    } catch (error) {
      console.error('Error in authorizePlatform:', error);
      setErrorMessage(`Error authorizing ${partner} platform`);
      setLoading(false);
      return null;
    }
  };

 /*  const exchangeCode = async (partner: Partner, code: string, userId: string, websiteIds: number[], twitterCodeVerifier?: string, state?: string) => {
    setLoading(true);
    setErrorMessage(null);
    const payload: any = {
      action: 'exchange',
      partner,
      code,
      userId,
      websiteIds
    };
    
    if (partner === 'x' && twitterCodeVerifier) {
      payload.twitterCodeVerifier = twitterCodeVerifier;
      payload.state = state;
    }
  
    if (fetchedRef.current) return;
      fetchedRef.current = true;
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
      if (result.status === 'success') {
        return result.data;
      } else {
        if (result.reason === 'token_expired') {
          await logoutUser();
        }
        setErrorMessage(result.message || `Failed to exchange auth code for ${partner}`);
        return null;
      }
    } catch (error) {
      setErrorMessage(`Error exchanging auth code for ${partner}`);
      setLoading(false);
      return null;
    }
  }; */

  const exchangeCode = async (partner: Partner, code: string, userId: string, websiteIds: number[], twitterCodeVerifier?: string, state?: string) => {
    setLoading(true);
    setErrorMessage(null);
    const payload: any = {
      action: 'exchange',
      partner,
      code,
      userId,
      websiteIds
    };
    
    if (partner === 'x' && twitterCodeVerifier) {
      payload.twitterCodeVerifier = twitterCodeVerifier;
      payload.state = state;
    }
  
    if (fetchedRef.current) return;
    fetchedRef.current = true;
    
    try {
      const response = await fetch('https://00ujjwhaed.execute-api.us-west-2.amazonaws.com/Prod/oauth', {
        method: 'POST',
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setLoading(false);
  
      if (result.message?.toLowerCase().includes('successfully') && result.tokens) {
        try {
          const authToken = localStorage.getItem('_auth');
          
          const promises = websiteIds.map(async (idSite) => {
            const heatmapPayload = new URLSearchParams({
              token: authToken || '',
              idSite: idSite.toString(),
              userId: userId,
              partner: partner,
              live: '1'
            });
  
            const heatmapResponse = await fetch(
              `https://stage1.heatmapcore.com/index.php?module=API&method=AdsIntegration.accounts`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: heatmapPayload
              }
            );
  
            if (!heatmapResponse.ok) {
              console.error(`Failed to update heatmap for site ${idSite}`);
            }
          });
  
          await Promise.all(promises);
        } catch (error) {
          console.error('Error updating heatmap:', error);
        }
  
        return result.tokens;
      } else {
        if (result.reason === 'token_expired') {
          await logoutUser();
        }
        setErrorMessage(result.message || `Failed to exchange auth code for ${partner}`);
        return null;
      }
    } catch (error) {
      setErrorMessage(`Error exchanging auth code for ${partner}`);
      setLoading(false);
      return null;
    }
  };

  return { getAllStatus, disconnectPlatform, authorizePlatform, exchangeCode, errorMessage, loading };
};
