// platformIcons.tsx
import React from 'react';
// export type Platform = 'google' | 'facebook' | 'x' | 'tiktok';
export const platformIcons: { [key: string]: JSX.Element } = {
    google: (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='mb-3'>
            <g clipPath="url(#clip0_145_11832)">
                <path d="M36.0244 18.4149C36.0244 17.1913 35.9252 15.9611 35.7135 14.7573H18.7354V21.6888H28.458C28.0545 23.9243 26.7582 25.9019 24.8599 27.1586V31.6561H30.6604C34.0666 28.5211 36.0244 23.8913 36.0244 18.4149Z" fill="#4285F4" />
                <path d="M18.7352 36.0012C23.5898 36.0012 27.6839 34.4073 30.6668 31.6558L24.8663 27.1583C23.2525 28.2562 21.1691 28.878 18.7418 28.878C14.0458 28.878 10.0642 25.7099 8.63557 21.4504H2.6499V26.0869C5.70557 32.1651 11.9293 36.0012 18.7352 36.0012V36.0012Z" fill="#34A853" />
                <path d="M8.62916 21.4503C7.87516 19.2148 7.87516 16.7941 8.62916 14.5585V9.92212H2.6501C0.0971006 15.0083 0.0971006 21.0006 2.6501 26.0867L8.62916 21.4503V21.4503Z" fill="#FBBC04" />
                <path d="M18.7351 7.12449C21.3014 7.08481 23.7816 8.05045 25.6402 9.823L30.7792 4.68392C27.5252 1.62826 23.2062 -0.051699 18.7351 0.001213C11.9293 0.001213 5.70557 3.83733 2.6499 9.92221L8.62896 14.5586C10.051 10.2926 14.0392 7.12449 18.7351 7.12449V7.12449Z" fill="#EA4335" />
            </g>
            <defs>
                <clipPath id="clip0_145_11832">
                    <rect width="36" height="36" fill="white" transform="translate(0.375)" />
                </clipPath>
            </defs>
        </svg>
    ),
    facebook: (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='mb-3'>
            <g clipPath="url(#clip0_145_11343)">
                <path d="M36.625 18C36.625 8.05887 28.5661 0 18.625 0C8.68387 0 0.625 8.05887 0.625 18C0.625 26.9842 7.2073 34.431 15.8125 35.7813V23.2031H11.2422V18H15.8125V14.0344C15.8125 9.52313 18.4998 7.03125 22.6114 7.03125C24.5801 7.03125 26.6406 7.38281 26.6406 7.38281V11.8125H24.3709C22.135 11.8125 21.4375 13.2001 21.4375 14.625V18H26.4297L25.6316 23.2031H21.4375V35.7813C30.0427 34.431 36.625 26.9842 36.625 18Z" fill="#1877F2" />
                <path d="M25.6316 23.2031L26.4297 18H21.4375V14.625C21.4375 13.2015 22.135 11.8125 24.3709 11.8125H26.6406V7.38281C26.6406 7.38281 24.5808 7.03125 22.6114 7.03125C18.4998 7.03125 15.8125 9.52313 15.8125 14.0344V18H11.2422V23.2031H15.8125V35.7813C17.6762 36.0729 19.5738 36.0729 21.4375 35.7813V23.2031H25.6316Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_145_11343">
                    <rect width="36" height="36" fill="white" transform="translate(0.625)" />
                </clipPath>
            </defs>
        </svg>
    ),
    x: (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='mb-3'>
            <g clipPath="url(#clip0_145_11865)">
                <path d="M11.4463 32.6261C25.0312 32.6261 32.4616 21.3711 32.4616 11.6108C32.4616 11.2911 32.4616 10.9729 32.44 10.6561C33.8856 9.61049 35.1334 8.31589 36.125 6.83286C34.7771 7.43046 33.3471 7.82219 31.8828 7.99494C33.4247 7.07197 34.5787 5.62011 35.13 3.90966C33.6801 4.77007 32.094 5.3764 30.4399 5.70246C29.3263 4.51833 27.8534 3.73422 26.2493 3.47149C24.6451 3.20875 22.9991 3.48203 21.566 4.24904C20.1328 5.01605 18.9923 6.23402 18.3211 7.71448C17.6499 9.19494 17.4853 10.8554 17.8528 12.4388C14.9163 12.2915 12.0436 11.5283 9.42118 10.1989C6.79873 8.86941 4.48515 7.00335 2.6306 4.72182C1.68609 6.34781 1.39681 8.27266 1.82165 10.1045C2.24649 11.9362 3.35351 13.5373 4.91732 14.5815C3.74185 14.5471 2.59197 14.23 1.565 13.657V13.7506C1.56547 15.4559 2.15578 17.1085 3.23581 18.4282C4.31584 19.7478 5.81909 20.6533 7.4906 20.9909C6.40322 21.2875 5.26231 21.3309 4.15556 21.1177C4.62772 22.5851 5.54664 23.8684 6.78386 24.7881C8.02107 25.7077 9.51474 26.2178 11.056 26.2469C9.52466 27.4507 7.77097 28.3406 5.89531 28.8659C4.01965 29.3913 2.05882 29.5416 0.125 29.3084C3.50275 31.4759 7.43289 32.6257 11.4463 32.6204" fill="#1DA1F2" />
            </g>
            <defs>
                <clipPath id="clip0_145_11865">
                    <rect width="36" height="36" fill="white" transform="translate(0.125)" />
                </clipPath>
            </defs>
        </svg>
    ),
    tiktok: (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" className='mb-3'>
            <path d="M26.6398 12.9952C28.9548 14.656 31.7909 15.6331 34.8539 15.6331V9.71791C34.2742 9.71804 33.696 9.65738 33.1289 9.53679V14.1929C30.0661 14.1929 27.2304 13.2157 24.9148 11.5551V23.6263C24.9148 29.6649 20.0369 34.5599 14.0201 34.5599C11.7751 34.5599 9.68846 33.8787 7.95508 32.7106C9.93345 34.7406 12.6924 36 15.7448 36C21.7619 36 26.6401 31.105 26.6401 25.0662V12.9952H26.6398ZM28.7678 7.02749C27.5847 5.73034 26.8079 4.05401 26.6398 2.20076V1.43994H25.0051C25.4166 3.79542 26.8201 5.8078 28.7678 7.02749ZM11.7607 28.0767C11.0997 27.2069 10.7425 26.1429 10.7441 25.0489C10.7441 22.287 12.9752 20.0477 15.7278 20.0477C16.2408 20.0476 16.7507 20.1264 17.2396 20.2821V14.2347C16.6682 14.1561 16.0916 14.1227 15.5153 14.135V18.842C15.026 18.6863 14.5159 18.6072 14.0028 18.6077C11.2502 18.6077 9.0192 20.8468 9.0192 23.609C9.0192 25.5621 10.1344 27.253 11.7607 28.0767Z" fill="#FF004F" />
            <path d="M24.9144 11.555C27.2301 13.2156 30.0657 14.1928 33.1285 14.1928V9.53667C31.4189 9.17121 29.9054 8.2746 28.7675 7.02749C26.8196 5.80768 25.4162 3.7953 25.0048 1.43994H20.7109V25.0659C20.7012 27.8202 18.4739 30.0504 15.7272 30.0504C14.1086 30.0504 12.6707 29.2761 11.76 28.0767C10.1338 27.253 9.0186 25.562 9.0186 23.6091C9.0186 20.8472 11.2496 18.6078 14.0022 18.6078C14.5296 18.6078 15.0379 18.6902 15.5147 18.8421V14.1351C9.60357 14.2576 4.84961 19.1048 4.84961 25.066C4.84961 28.0419 6.03346 30.7396 7.95485 32.7107C9.68823 33.8787 11.7749 34.56 14.0199 34.56C20.0368 34.56 24.9146 29.6648 24.9146 23.6263V11.555H24.9144Z" fill="black" />
            <path d="M33.1288 9.5367V8.27773C31.5871 8.28007 30.0757 7.84678 28.7677 7.0274C29.9256 8.29959 31.4503 9.1768 33.1288 9.5367ZM25.005 1.43998C24.9658 1.21487 24.9356 0.988276 24.9147 0.760821V0H18.986V23.6262C18.9766 26.3801 16.7494 28.6103 14.0025 28.6103C13.196 28.6103 12.4346 28.4182 11.7603 28.0769C12.6709 29.2762 14.1089 30.0503 15.7275 30.0503C18.4739 30.0503 20.7016 27.8204 20.7112 25.0661V1.43998H25.005ZM15.5152 14.1351V12.7948C15.0198 12.7269 14.5204 12.6928 14.0203 12.693C8.00287 12.6929 3.125 17.5881 3.125 23.6262C3.125 27.4118 5.04209 30.748 7.95525 32.7105C6.03386 30.7395 4.85001 28.0416 4.85001 25.0659C4.85001 19.1048 9.60385 14.2577 15.5152 14.1351Z" fill="#00F2EA" />
        </svg>
    ),
};
